import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        {/* Logo Section */}
        <div className="footer-section logo-section">
          <img src="/logo.png" alt="Logo" className="logo" />
        </div>

        {/* Quick Links */}
        <div className="footer-section quick-links">
          <h3>Quick Links</h3>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/about">About</a></li>
            <li><a href="/contact">Contact</a></li>
          </ul>
        </div>

        {/* Social Media Links */}
        <div className="footer-section social-links">
          <h3>Follow Us</h3>
          <div className="icons">
            <a href="https://www.facebook.com/share/Bq2tQiNTPQzFrtAS/?mibextid=qi2Omg" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} size="2x" />
            </a>
            <a href="https://www.instagram.com/p/DADr1OLNS8Z/?igsh=MW45b3lwZ3c1NThyZw==" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} size="2x" />
            </a>
          </div>
        </div>

        {/* Contact Information */}
        <div className="footer-section contact-number">
          <h3>Contact</h3>
          <p>Phone: 07034617936, +234 703 692 8121</p>
          <p>Email: <a href="mailto:theonunufestival@gmail.com">theonunufestival@gmail.com</a></p>
        </div>
      </div>

      {/* Footer Bottom Section */}
      <div className="footer-bottom">
        <p>&copy; 2024 OnunuFestival. All Rights Reserved. Developed by Timzdigital</p>
      </div>
    </footer>
  );
};

export default Footer;
